/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  useMediaQuery,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTrashAlt, faPlus, faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@mui/material/styles';
import { AldgDataGrid, InputAutocomplete } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { deleteDoc, onSnapshot, setDoc, doc, collection, query, where, orderBy } from 'firebase/firestore';
import { useGridApiRef, LicenseInfo } from '@mui/x-data-grid-premium';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { _CurrentForeman, _CurrentJobNumber } from '../../_Recoil/atoms';
import QuickSearchToolbar from '../../_GlobalComponents/QuickFilterToolbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1)
  }
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const gridStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: '#fff'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#fff'
      }
    }
  }),
  { index: 1 }
);

LicenseInfo.setLicenseKey('3531a0fd8b8f5639cd90e1f16f9621beTz0xMDcwNzksRT0xNzY5OTkwMzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');
const CrewList = (props) => {
  const { history } = props;
  const [Crew, setCrew] = useState([]);
  const [JobCrew] = useState([]);
  const [selection, setSelection] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [setRows] = useState(Crew);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentJobNumber = useRecoilValue(_CurrentJobNumber);
  const gridClasses = gridStyles();
  const GridApiRef = useGridApiRef();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));


  const addNew = () => {
    if (CurrentForeman.value.length > 0) history.push(`/Crew/new`);
    else toast.error('You must select a Manager before adding Crew.');
  };


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = Crew.filter((row) =>
      Object.keys(row.data).some((field) =>
        searchRegex.test(row.data[field].toString())
      )
    );
    setRows(filteredRows);
  };
  const deleteRecords = () => {
    const selectedRows = GridApiRef.current.getSelectedRows();
    selectedRows.forEach((s) => {
      deleteDoc(doc(firestore, 'Crew-Manager', s.id));
    });
  };

  const handleSelect = (c) => {
    if (CurrentForeman.value.length > 0) history.push(`/Crew/${c}`);
    else toast.error('A Manager needs to be selected to add a Crew Member');
  };

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);
  const columns = [
    {
      field: 'CrewMember',
      headerName: 'Name',
      valueGetter: (params) =>
        params.row.data.EmployeeDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    },
    {
      field: 'Foreman',
      headerName: 'Foreman',
      valueGetter: (params) => params.row.data.ForemanDisplay,
      flex: getFlex(15),
      width: getWidth(300)
    }
  ];

  useEffect(() => {
    let mounted = true;
    if (CurrentForeman.value.length > 0) {
      const q = query(
        collection(firestore, 'Crew-Manager'),
        where('data.Foreman', '==', CurrentForeman.value[0]),
        orderBy('data.EmployeeDisplay'));
      onSnapshot(q, (snap) => {
        const all = [];
        snap.forEach(d => {
          all.push(d.data());
        });
        if (mounted) {

          setCrew(all);
        }
      });
    } else {
      setCrew([]);
    }
    return () => (mounted = false);

  }, [CurrentForeman]);

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '16px',
          position: 'sticky',
          top: '0px',
          zIndex: '100',
          backgroundColor: '#f4f6f8',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: matches ? '20px 0' : '10px 0',
            justifyContent: 'flex-end'
          }}
        >
          <ToolTipIconButton title='Delete Selected Records' onClick={deleteRecords} icon={faTrashAlt} color="error" />
          <ToolTipIconButton title='Add New Crew Member' onClick={addNew} icon={faPlus} />

        </div>

      </div>
      <AldgDataGrid
        autoHeight
        checkboxSelection
        containerHeight="700px"
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch('')
          }
        }}
        onRowClick={(row) => handleSelect(row.id)}
        className={gridClasses.root}
        rows={Crew}
        columns={columns}
        apiRef={GridApiRef}
      />

    </>
  );
};
CrewList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
CrewList.defaultProps = {};

const CrewListSelection = (props) => {
  // const classes = basicStyles();
  const customClasses = useStyles();
  const { history, match } = props;
  const [crews, setCrews] = useState([]);
  const { user } = useContext(UserContext);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const mounted = useRef(null);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: '',
      Foreman: CurrentForeman.value[0] || '',
      ForemanDisplay: CurrentForeman.shortDisplay || '',
      Employee: [],
      Union: [],
      GroupName: [],
    }
  });

  useEffect(() => {
    if (match.params.id === 'new') return;
    mounted.current = true;
    onSnapshot(doc(firestore, 'Crew-Manager', match.params.id), (snap) => {
      if (snap.exists) {
        const r = snap.data();
        if (mounted.current)
          setRecord({ ...r });
      }
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted.current = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getRecords = () => {
    const colRefJob = (() => {
      let col = collection(firestore, `Crew-Manager`);
      col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]), orderBy('data.EmployeeDisplay'));
      return col;
    })();
    const allCrewMembers = [];
    if (typeof colRefJob !== 'undefined') {
      onSnapshot(colRefJob, (res) => {
        res.forEach((d) => allCrewMembers.push(d.data()));
      });
    } else if (mounted.current) {
      setCrews([]);
      return;
    }
    if (mounted.current) setCrews(allCrewMembers);
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current)
      getRecords();
    return () => (mounted.current = false);
  }, [CurrentForeman]);

  const addCrewMember = (event, newRecord) => {
    const savedRecord = JSON.parse(JSON.stringify(record));
    if (record.data.Employee.length === 0) {
      toast.error('You need to select both an Employee to add them to your crew.');
      return;
    }
    for (let i = 0; i < crews.length; i++) {
      if (savedRecord.data.Employee[0] === crews[i].data.Employee[0] && (CurrentForeman.value[0] || '') === crews[i].data.Foreman && crews[i].id !== savedRecord.id) {
        toast.error(`${savedRecord.data.EmployeeDisplay} is already in this crew.`, { toastId: 'DuplicateCrewMember' });
        return;
      }
    }
    if (match.params.id === 'new') {
      const docRef = doc(collection(firestore, 'Crew-Manager'));
      savedRecord.id = docRef.id;
      setDoc(docRef, savedRecord);
      toast.success('Crew Member Added.');
    } else {
      setDoc(doc(firestore, 'Crew-Manager', savedRecord.id), savedRecord, { merge: true });
    }
    toast.success(`Successfully added ${record.data.EmployeeDisplay}`);
    if (newRecord) {
      setRecord({
        id: '',
        CreatedBy: '',
        CreatedDate: '',
        ModifiedBy: '',
        ModifiedDate: '',
        data: {
          JobNumber: '',
          Foreman: CurrentForeman.value[0] || '',
          ForemanDisplay: CurrentForeman.shortDisplay || '',
          Employee: [],
        }
      });
      history.push('/Crew/new');
    } else {
      history.push('/Crew');
    }
  };
  const backToCrew = () => {
    history.push('/Crew');
  };

  const onEmployeeChange = (event, name, displayFromTypeahead) => {
    onChange(event, name, displayFromTypeahead.split(' - [')[0]);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ paddingTop: '16px' }}>
      <div style={{
        position: 'sticky', top: '-1px', left: 0, right: 0, width: '100%', padding: '8px', paddingRight: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <ToolTipIconButton tooltipText='Save Record' onClick={addCrewMember} icon={faSave} color="success" />
        <ToolTipIconButton tooltipText='Save & New Record' onClick={(event) => addCrewMember(event, true)} icon={faSparkles} color="primary" />
        <ToolTipIconButton tooltipText='Back To Crew List' onClick={backToCrew} icon={faTimes} color="error" />
      </div>
      <Paper className={customClasses.paper}>
        Don&apos;t add Office Personnel to Crew.
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: '8px' }}>
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              label='Employee'
              firestoreOptions={{
                method: 'get',
                collection: 'ENT-Employees',
                where: [['Status', '==', 'A'],
                ['EmployeeType', 'in', ['Field', 'Fleet']]],
                orderBy: ['LegalLastName', 'PreferredFirstName'],
                valueKey: 'prtmstid',
              }}
              name='Employee'
              onChange={onEmployeeChange}
              optionDisplay={(opts) => {
                let display = `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
                  } ${opts.MiddleName || ''} - [${opts.Title} - ${opts.UniversalID
                  }]`.replace(/ {2}/g, ' ');
                if (Object.keys(opts).length === 0) return '';
                if (opts.IsAldridgeEmployee === false) {
                  display += ` - [${opts.CompanyDisplay}]`;
                }
                return display;
              }}
              value={record.data.Employee || []}
            />
          </Grid>
          <Grid item xs={12}>
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              label='Group'
              firestoreOptions={{
                method: 'get',
                collection: 'Group-Manager',
                where: [['data.Status', '==', 'Active'], ['data.Foreman', '==', CurrentForeman.value[0]]],
                orderBy: ['data.GroupName'],
                valueKey: 'id',
              }}
              name='GroupName'
              onChange={onChange}
              optionDisplay={(opts) => opts?.data?.GroupName || ""}
              value={record.data.GroupName || []}
              enterprise={false}
            />
          </Grid>
        </Grid>
      </Paper>

    </div>
  );
};


CrewListSelection.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
CrewListSelection.defaultProps = {};

export { CrewListSelection, CrewList };
