/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  useMediaQuery,
  Box
} from '@mui/material';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { faPlus, faThumbsUp, faThumbsDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';

import { existsWithLength, isUndefined, NaNtoNum } from '@aldridge/aldg-helpers';
import { DataGridPremium, LicenseInfo } from '@mui/x-data-grid-premium';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { firestore } from '../../firebase';
import { _CurrentDate, _CurrentForeman } from '../../_Recoil/atoms';
import TimeSummaryModalRecord from './TimeSummaryModalRecord';
import { weekEndDate } from '../../utils/dateFunctions';

import { UserContext } from '../../providers/UserProvider';
import { checkTime } from '../../utils/checkLockout';

const TimeSummaryDetailPanel = (props) => {
  const [timeEntries, setTimeEntries] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const mounted = useRef(null);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const { row, apiRef, queuePiccs } = props;
  const { Employee: employeeId } = row;
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(undefined);

  const user = useContext(UserContext);
  const checkLockout = checkTime(user, CurrentDate);
  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const theme = useTheme();

  const reset = (r, d, t) => {
    try {
      if (!r[d]) {
        r[d] = {};
      }
      r[d][t] = 0;
      r[d].verified = [];
      // eslint-disable-next-line no-empty
    } catch (e) { }
  };
  const getNewTimeEntries = (allTimeEntries) => {
    const r = JSON.parse(JSON.stringify(row));
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const types = ['Regular', 'Overtime', 'Doubletime', 'Holiday', 'Vacation', 'SickLeave', 'PerDiem'];
    days.forEach((d) => {
      types.forEach((t) => {
        reset(r, d, t);
      });
    });
    r.Piccs = [];
    JSON.parse(JSON.stringify(allTimeEntries)).forEach((entry) => {

      const d = dayjs(entry?.data?.Date).format('ddd');
      if (entry.data.NoWorkRecorded !== 'true') {
        r.Piccs.push(entry.data.Picc[0]);
        if (!isUndefined(entry.data.Picc[0]))
          queuePiccs(entry.data.Picc[0]);
      }
      r.Employee = entry.data.Employee;
      r.EmployeeDisplay = entry.data.EmployeeDisplay;
      r[d].verified = r[d].verified || [];
      r[d].verified.push(entry.data?.verified || false);
      r[d].LessThan8HoursVerified =
        entry.data.LessThan8HoursVerified === 'true';
      r[d].Regular += NaNtoNum(entry.data.Regular);
      r[d].Overtime += NaNtoNum(entry.data.Overtime);
      r[d].Doubletime += NaNtoNum(entry.data.Doubletime);
      r[d].Holiday += NaNtoNum(entry.data.Holiday);
      r[d].Vacation = NaNtoNum(entry.data.Vacation);
      r[d].SickLeave += NaNtoNum(entry.data.SickLeave);
      r[d].PerDiem += NaNtoNum(entry.data.PerDiem);

      r[d].NoWorkRecorded = entry.data.NoWorkRecorded === 'true';

    });
    apiRef.current.updateRows([r]);
  };
  const AddTime = () => {
    if (CurrentForeman.value.length !== 0 && CurrentDate.value !== '') {
      setSelectedRecord(undefined);
      displayChangeBox(true);
    } else {
      toast.error('You must have a Job and Date Selected to enter time...');
    }
  };

  const getRecords = () => {
    if (CurrentForeman.value.length === 0) {
      if (mounted.current) setTimeEntries([]);
      return;
    }

    const colRef = (() => {
      let col = query(collection(firestore, `TimeEntries`));
      col = query(
        col,
        where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))
      );
      if (!(user.manager || user.admin)) {
        col = query(col, where('CreatedBy', '==', user.email));
      }
      // 'rschuman@aldridge-electric.com'
      // col = col.where('CreatedBy', '==', 'rschuman@aldridge-electric.com');
      col = query(col, where('data.Employee', '==', employeeId));
      col = query(col, orderBy('data.EmployeeDisplay'));
      col = query(col, orderBy('data.Date'));
      return col;
    })();

    onSnapshot(colRef, (res) => {
      const allTimeEntries = [];
      res.forEach((d) => allTimeEntries.push(d.data()));
      if (mounted.current) setTimeEntries(allTimeEntries);
      getNewTimeEntries(allTimeEntries);
    });
  };

  const verifiedHours = (record) => {
    record.data.verified = !record.data.verified;
    setDoc(doc(firestore, 'TimeEntries', record.id), record, { merge: true });
  };

  const checkModalRecord = async (r) => {
    if (!checkLockout) {
      setSelectedRecord(r);
      displayChangeBox(true);
    } else {
      toast.error('This time period has been locked. No more edits can be made.');
    }
  };

  const removeRecord = async (r) => {
    await deleteDoc(doc(firestore, 'TimeEntries', r.id));
    getRecords();
    props.refreshTimeEntries();
    toast.success(`Removed ${r.data.EmployeeDisplay} time Record.`);
  };


  // const iconClasses = icons();
  const closePopup = () => {
    displayChangeBox(false);
  };

  useEffect(() => {
    mounted.current = true;
    getRecords();
    return () => (mounted.current = false);
  }, [CurrentForeman, CurrentDate]);

  useEffect(() => {
    if (timeEntries.length > 0) {
      const uniqueTasks = [...new Set(timeEntries.map(t => t.data.TaskID))];
      const all = [];
      uniqueTasks.forEach(ut => {
        if (existsWithLength(ut))
          all.push(getDoc(doc(firestore, 'Tasks', ut)));
      });

      Promise.all(all).then(res => {
        const orders = [];
        res.forEach(r => {
          if (r.exists()) {
            const rec = r.data();
            if (rec.data.TaskType === 'PreFab') {
              orders.push({ Task: rec.id, OrderNumber: rec.data.OrderNumber, OrderNumberDisplay: rec.data.OrderNumberDisplay });
            }
          }
        });
        setOrderNumbers(orders);
      });
    }
  }, [timeEntries]);

  const verifyButton = (record) => {
    if (!checkLockout) {
      if (record.data?.verified) {
        return (<FontAwesomeIcon size="xl" style={{ cursor: 'pointer' }} icon={faThumbsUp} onClick={(e) => {
          verifiedHours(record);
        }} />);
      }
      return (
        <FontAwesomeIcon size="xl" style={{ cursor: 'pointer' }} icon={faThumbsDown} onClick={(e) => {
          verifiedHours(record);
        }} />);

    }
    return <span />;
  };
  const columns = [
    {
      field: 'Date',
      headerName: 'Date',
      width: 150,
      valueGetter: (params) => {
        if (params.rowNode.isAutoGenerated) return '';
        return dayjs(params.row.data?.Date).format('ddd MMM D, YYYY');
      }
    },
    {
      field: 'Job',
      headerName: 'Job / PICC',
      width: 250,
      renderCell: (params) => {
        if (params.rowNode.isAutoGenerated) return '';
        if (params.row.data?.JobNumberDisplay?.split(' - ')[0].length > 0) return (<span>{params.row.data?.JobNumberDisplay?.split(' - ')[0]} <br />{params.row.data?.PiccDisplay}</span>);
        if (params.row.data?.JobDisplay?.split(' - ')[0].length > 0) return (<span>{params.row.data?.JobDisplay?.split(' - ')[0]} <br />{params.row.data?.PiccDisplay}</span>);
        return '';
      }
    },
    {
      field: 'Union',
      headerName: 'Union',
      width: 150,
      valueGetter: (params) => params.row.data?.UnionDisplay
    },
    {
      field: 'Regular',
      headerName: 'Regular',
      type: 'number',
      width: 100,
      valueGetter: (params) => NaNtoNum(params.row.data?.Regular)

    },
    {
      field: 'Overtime',
      headerName: 'Overtime',
      type: 'number',
      width: 100,
      valueGetter: (params) => NaNtoNum(params.row.data?.Overtime)
    },
    {
      field: 'Doubletime',
      headerName: 'Doubletime',
      type: 'number',
      width: 100,
      valueGetter: (params) => NaNtoNum(params.row.data?.Doubletime)
    },
    {
      field: 'VacHolSL',
      headerName: 'Vac/Hol/SL',
      type: 'number',
      width: 100,
      valueGetter: (params) => (
        NaNtoNum(params.row.data?.Vacation) +
        NaNtoNum(params.row.data?.SickLeave) +
        NaNtoNum(params.row.data?.Holiday)
      )
    },
    {
      field: 'PerDiem',
      headerName: 'Per Diem',
      type: 'number',
      width: 100,
      valueGetter: (params) => NaNtoNum(params.row.data?.PerDiem)
    },
    {
      field: 'Verified',
      headerName: 'Verified',
      width: 100,
      renderCell: (params) => {
        if (params.rowNode.isAutoGenerated) return '';
        return verifyButton(params.row);
      }
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      width: 100,
      renderCell: (params) => {
        if (params.rowNode.isAutoGenerated) return '';
        return (
          !checkLockout ? <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ cursor: 'pointer' }}
            onClick={() => removeRecord(params.row)}
            size="xl"
          /> : <div />);
      }
    }
  ];
  LicenseInfo.setLicenseKey(
    '3531a0fd8b8f5639cd90e1f16f9621beTz0xMDcwNzksRT0xNzY5OTkwMzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
  );
  return (
    <Box sx={{ height: '500px', overflow: 'auto', zoom: '.9' }}>
      <div style={{ paddingTop: '1rem' }}>
        Time Summary for{' '}
        {timeEntries.length > 0 ? (
          <b>{timeEntries[0].data.EmployeeDisplay}</b>
        ) : null}{' '}
        on Week Ending{' '}
        <b>{dayjs(weekEndDate(CurrentDate.value)).format('MMMM D, YYYY')}</b>
      </div>
      <Grid container>

        <Modal open={displayBoxOpen}>
          <div className='timePaper'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <TimeSummaryModalRecord
                record={selectedRecord}
                closePopup={closePopup}
                employee={employeeId}
              />
            </div>
          </div>
        </Modal>
        <Grid container style={{ display: 'contents' }}>
          <div
            style={{
              position: 'sticky',
              backgroundColor: 'white',
              borderBottom: '1px solid #ccc',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              alignSelf: 'flex-start'
            }}
          >
            {!checkLockout &&
              <ToolTipIconButton tooltipText="Add a Record" onClick={AddTime} icon={faPlus} />}
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <DataGridPremium
              aggregationModel={{
                Regular: 'sum',
                Overtime: 'sum',
                Doubletime: 'sum',
                VacHolSL: 'sum',
                PerDiem: 'sum'
              }}
              className="aldg-doubleBorder"
              rows={timeEntries}
              columns={columns}
              onRowClick={(params) => checkModalRecord(params.row)}
              onCellClick={(params, e) => {

                if (params.field === 'Verified') {
                  e.stopPropagation();
                }
                if (params.field === 'Delete') {
                  e.stopPropagation();
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
TimeSummaryDetailPanel.propTypes = {
  row: PropTypes.object.isRequired,
  apiRef: PropTypes.object.isRequired,
  queuePiccs: PropTypes.func.isRequired,
  refreshTimeEntries: PropTypes.func.isRequired
};


export default TimeSummaryDetailPanel;
