/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Grid,
  Button,
  Tooltip,
  Box,
  Modal,
  Paper
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import { collection, getDocs, query, doc, setDoc, where, orderBy, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useRecoilValue, useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { faThumbsUp, faThumbsDown, faTimes, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { faQuestionCircle, faUser, faUserSlash, faUserXmark } from '@fortawesome/pro-regular-svg-icons';
import { DataGridPremium, GridToolbar, LicenseInfo, useGridApiRef } from '@mui/x-data-grid-premium';
import { isUndefined, NaNtoNum } from '@aldridge/aldg-helpers';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { firebaseConfig, firestore } from '../../firebase';
import TimeSummaryDetailPanel from './TimeSummaryDetailPanel';

import {
  _CurrentDate,
  _CurrentForeman,
  _GroupName
} from '../../_Recoil/atoms';
import { weekEndDate } from '../../utils/dateFunctions';
import TimeSummaryLegend from './TimeSummaryLegend';

const TimeSummaryList = () => {
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const [NewTimeEntries, setNewTimeEntries] = useState([]);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [Piccs, setPiccs] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState([]);
  const [aggTimeDisplay, setAggTimeDisplay] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const masterPiccs = [];
  const [GroupName, setGroupName] = useRecoilState(_GroupName);

  const TimeDocs = (time, allDocs) => {
    if (!time.empty) {
      time.forEach((d) => {
        const emp = d.data().data.Employee;
        if (
          allDocs[`Employee${emp}`].time
            .map((r) => r.id)
            .indexOf(d.data().id) === -1
        )
          allDocs[`Employee${emp}`].time.push(d.data());
      });
    }
  };
  const getUniqueEmployees = (EmployeePids, uniqueEmployees, crews) => {
    crews.forEach((crew) =>
      EmployeePids.push({
        prtmstid: crew.data().data?.Employee[0],
        name: `${crew.data().data?.EmployeeDisplay}`,
        foreman: [crew.data().data.ForemanDisplay]
      })
    );
    EmployeePids.forEach((emp) => {
      const idx = uniqueEmployees
        .map((e) => e.prtmstid)
        .findIndex((e) => e === emp.prtmstid);
      if (idx === -1) {
        uniqueEmployees.push(emp);
      } else {
        uniqueEmployees[idx].foreman.push(emp.foreman[0]);
      }
    });
    uniqueEmployees.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  };
  const getNewJobsRecords = () => {
    const uniqueEmployees = [];
    const allTimeCalls = [];
    const allDocs = {};
    let q = query(collection(firestore, 'Crew-Manager'), where('data.Foreman', '==', CurrentForeman.value[0]), orderBy('data.EmployeeDisplay'));
    if (GroupName.length > 0) q = query(q, where("data.GroupName", "array-contains", GroupName[0]));

    getDocs(q).then((allCrews) => {
      const EmployeePids = [];
      getUniqueEmployees(EmployeePids, uniqueEmployees, allCrews);

      let batched = [];
      for (let e = 0; e < uniqueEmployees.length; e++) {
        allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
          name: uniqueEmployees[e].name,
          prtmstid: uniqueEmployees[e].prtmstid,
          foreman: uniqueEmployees[e].foreman,
          time: []
        };
        if (batched.length === 10) {
          allTimeCalls.push(
            getDocs(query(collection(firestore, 'TimeEntries'), where('data.Employee', 'in', batched), where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))))
          );
          batched = [];
        }
        batched.push(uniqueEmployees[e].prtmstid);
      }
      allTimeCalls.push(
        getDocs(query(collection(firestore, 'TimeEntries'), where('data.Employee', 'in', batched), where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))))
      );
      Promise.all(allTimeCalls).then((t) => {
        t.forEach((time) => {
          TimeDocs(time, allDocs);
        });
        setEmployeeIds(uniqueEmployees);
        setNewTimeEntries(allDocs);
      });
    });
  };
  const getRecords = (isMounted) => {
    if (CurrentForeman.value.length === 0 || CurrentDate.value === '') {
      if (isMounted) setNewTimeEntries([]);
      return;
    }
    getNewJobsRecords();
  };

  // onPageLoad, DateChange, ForemanChange, GroupChange Load Initial Data
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getRecords(mounted);
    }
    return () => (mounted = false);
  }, [CurrentDate.value, CurrentForeman.value, GroupName]);

  const onGroupChange = (event) => {
    setGroupName(event);
  };

  const queuePiccs = (jctmstid) => {
    if (Piccs.map((p) => p.jctmstid).includes(jctmstid)) return;
    getDoc(doc(firestore, 'ENT-Piccs', jctmstid)).then((d) => {
      setPiccs((prev) => {
        if (prev.map((p) => p.jctmstid).includes(jctmstid)) return prev;
        return [...prev, d.data()];
      });
    });
  };

  const getNewTimeEntries = (tE, mounted, filter = 0) => {
    const aggTimeRecords = [];
    const r = {
      Employee: '',
      EmployeeDisplay: '',
      Foremans: [],
      Date: '',
      Piccs: [],
    };
    Object.values(tE).forEach((te) => {
      const { name, time, prtmstid, foreman } = te;
      if (time.length > 0) {
        time.forEach((entry) => {
          const idx = aggTimeRecords.findIndex(
            (atr) => atr.Employee === entry.data.Employee
          );
          const d = dayjs(entry?.data?.Date).format('ddd');
          if (idx === -1) {
            const cR = JSON.parse(JSON.stringify(r));
            if (entry.data.NoWorkRecorded !== 'true') {
              cR.Piccs.push(entry.data.Picc[0]);
              masterPiccs.push(entry.data.Picc[0]);
              if (!isUndefined(entry.data.Picc[0]))
                queuePiccs(entry.data.Picc[0]);
            }
            cR.Employee = entry.data.Employee;
            cR.EmployeeDisplay = entry.data.EmployeeDisplay;
            cR.Foremans = foreman;
            cR.Date = entry.data.Date;
            cR[d] = {};
            cR[d].verified = cR[d].verified || [];
            cR[d].verified.push(entry.data.verified || false);
            cR[d].LessThan8HoursVerified =
              entry.data.LessThan8HoursVerified === 'true';
            if (filter === 0 || filter === 1)
              cR[d].Regular = NaNtoNum(entry.data.Regular);
            if (filter === 0 || filter === 2)
              cR[d].Overtime = NaNtoNum(entry.data.Overtime);
            if (filter === 0 || filter === 3)
              cR[d].Doubletime = NaNtoNum(entry.data.Doubletime);
            if (filter === 0)
              cR[d].Holiday = NaNtoNum(entry.data.Holiday);
            if (filter === 0 || filter === 4)
              cR[d].Vacation = NaNtoNum(entry.data.Vacation);
            if (filter === 0)
              cR[d].SickLeave = NaNtoNum(entry.data.SickLeave);
            if (filter === 0 || filter === 5)
              cR[d].PerDiem = NaNtoNum(entry.data.PerDiem);
            if (filter === 0)
              cR[d].NoWorkRecorded = entry.data.NoWorkRecorded === 'true';
            aggTimeRecords.push(cR);
          } else {
            const cR = aggTimeRecords[idx];
            if (typeof cR[d] === 'undefined')
              cR[d] = {
                Regular: 0,
                Overtime: 0,
                Doubletime: 0,
                Vacation: 0,
                SickLeave: 0,
                Holiday: 0
              };
            if (entry.data.NoWorkRecorded !== 'true') {
              cR.Piccs.push(entry.data.Picc[0]);
              masterPiccs.push(entry.data.Picc[0]);
              if (!isUndefined(entry.data.Picc[0]))
                queuePiccs(entry.data.Picc[0]);
            }
            cR[d].LessThan8HoursVerified = !cR[d].LessThan8HoursVerified
              ? entry.data.LessThan8HoursVerified === 'true'
              : cR[d].LessThan8HoursVerified;
            if (filter === 0 || filter === 1)
              cR[d].Regular += NaNtoNum(entry.data.Regular);
            if (filter === 0 || filter === 2)
              cR[d].Overtime += NaNtoNum(entry.data.Overtime);
            if (filter === 0 || filter === 3)
              cR[d].Doubletime += NaNtoNum(entry.data.Doubletime);
            if (filter === 0)
              cR[d].Holiday += NaNtoNum(entry.data.Holiday);
            if (filter === 0 || filter === 4)
              cR[d].Vacation += NaNtoNum(entry.data.Vacation);
            if (filter === 0)
              cR[d].SickLeave += NaNtoNum(entry.data.SickLeave);
            if (filter === 0 || filter === 5)
              cR[d].PerDiem = NaNtoNum(entry.data.PerDiem);
            if (filter === 0)
              cR[d].NoWorkRecorded = !cR[d].NoWorkRecorded
                ? entry.data.NoWorkRecorded === 'true'
                : cR[d].NoWorkRecorded;

            cR[d].verified = cR[d].verified || [];
            cR[d].verified.push(entry.data.verified || false);
          }
        });
      } else {
        const cR = JSON.parse(JSON.stringify(r));
        cR.Employee = prtmstid;
        cR.EmployeeDisplay = name;
        cR.Foremans = foreman;
        aggTimeRecords.push(cR);
      }
    });
    if (mounted) {
      setAggTimeDisplay(aggTimeRecords);
    }
  };
  const displayDailyTotalHrs = (day) => {
    if (typeof day === 'undefined') return '';
    if (day.NoWorkRecorded) {
      return 'X';
    }
    return `${NaNtoNum(day.Regular) +
      NaNtoNum(day.Overtime) +
      NaNtoNum(day.Doubletime) +
      NaNtoNum(day.Holiday) +
      NaNtoNum(day.Vacation) +
      NaNtoNum(day.SickLeave)
      }<br>${day.PerDiem !== '' && typeof day.PerDiem !== 'undefined' && day.PerDiem.toString() !== '0'
        ? `$${day.PerDiem.toString()}`
        : ''
      }`;
  };

  const getColor = (day) => {
    if (typeof day !== 'undefined') {
      if (
        day.NoWorkRecorded &&
        (day.Doubletime > 0 ||
          day.Overtime > 0 ||
          day.Vacation > 0 ||
          day.Holiday > 0 ||
          day.SickLeave > 0 ||
          day.Regular > 0)
      )
        return '#dbdd2e';
      if (day.Doubletime > 0 || day.Overtime > 0) return 'red';
      if (day.Regular > 8) return 'darkorange';
      if (day.Vacation > 0 || day.Holiday > 0) return 'blue';
      if (day.SickLeave > 0) return 'green';
      if (day.NoWorkRecorded || day.LessThan8HoursVerified)
        return 'darkturquoise';
    }
    return 'black';
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) getNewTimeEntries(NewTimeEntries, mounted);
    return () => (mounted = false);
  }, [employeeRecords, NewTimeEntries]);

  useEffect(() => {
    let mounted = true;
    const allEmp = [];
    employeeIds.forEach((ei) => {
      allEmp.push(getDoc(doc(firestore, 'ENT-Employees', ei.prtmstid)));
    });
    const allEmpRecords = [];
    Promise.all(allEmp).then((res) => {
      res.forEach((d) => {
        allEmpRecords.push(d.data());
      });
      if (mounted) setEmployeeRecords(allEmpRecords);
    });
    return () => {
      mounted = false;
    };
  }, [employeeIds]);

  const managerOverride = (employee, allApproved) => new Promise((resolve) => {
    const all = [];
    const timeEntries = NewTimeEntries[`Employee${employee}`]?.time || [];
    if (allApproved) {
      timeEntries.forEach((r) => {
        r.data.verified = true;
        all.push(setDoc(doc(firestore, 'TimeEntries', r.id), r, { merge: true }));
      });
    } else {
      timeEntries.forEach((r) => {
        r.data.verified = false;
        all.push(setDoc(doc(firestore, 'TimeEntries', r.id), r, { merge: true }));
      });
    }

    Promise.all(all).then(() => {
      resolve();
    });
  });
  const layoffOverride = (record, laidOff) => {
    const updatedRecord = { ...record };
    if (laidOff) {
      updatedRecord.data.LaidOff = true;
      return setDoc(doc(firestore, 'TimeEntries', record.id), updatedRecord, { merge: true });
    }
    updatedRecord.data.LaidOff = false;
    return setDoc(doc(firestore, 'TimeEntries', record.id), updatedRecord, { merge: true });
  };

  const verifiedButton = (params) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let verifiedArray = [];
    days.forEach((d) => {
      if (typeof params.row[d] !== 'undefined') {
        verifiedArray = [...verifiedArray, ...params.row[d].verified];
      }
    });
    const verified = verifiedArray.filter((vA) => (vA || false) === true).length > 0;
    const unverified = verifiedArray.filter(vA => (vA || false) === false).length > 0;
    if (verified && unverified) {
      return (
        <Tooltip title="User has had time added since being approved">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '40px',
            minWidth: '40px',
            maxWidth: '40px',
          }}
            onClick={() => {
              managerOverride(params.row.Employee, false).then(getNewJobsRecords);
            }}
          >
            <FontAwesomeIcon icon={faQuestion} size="2x" />
          </Button>
        </Tooltip>
      );
    }
    if (verified && !unverified) {
      return (
        <Tooltip title="Unapprove User Time">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '40px',
            minWidth: '40px',
            maxWidth: '40px',
          }}
            onClick={() => {
              managerOverride(params.row.Employee, false).then(getNewJobsRecords);
            }}

          >
            <FontAwesomeIcon icon={faThumbsUp} size="2x" />
          </Button>
        </Tooltip>
      );
    }
    if (!verified && !unverified) {
      // set 
      return (
        <Tooltip title="User has no Time to approve">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '40px',
            minWidth: '40px',
            maxWidth: '40px',
          }}
            onClick={() => {
              toast.info('No records to approve');
            }}

          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </Button>
        </Tooltip>
      );
    }

    if (!verified && unverified) {
      // set  
      return (
        <Tooltip title="Approve User Time">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '40px',
            minWidth: '40px',
            maxWidth: '40px',
          }}
            onClick={() => {
              managerOverride(params.row.Employee, true).then(getNewJobsRecords);
            }}

          >
            <FontAwesomeIcon icon={faThumbsDown} size="2x" />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };
  const CrewHours = (crew) => {
    let regHrs = 0;
    let overHrs = 0;
    let otherHrs = 0;

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    days.forEach((d) => {
      if (typeof crew[d] !== 'undefined') {
        regHrs += NaNtoNum(crew[d].Regular);
        overHrs += NaNtoNum(crew[d].Overtime);
        otherHrs += NaNtoNum(crew[d].Doubletime) + NaNtoNum(crew[d].Holiday) + NaNtoNum(crew[d].Vacation) + NaNtoNum(crew[d].SickLeave);
      }
    });
    return [regHrs.toFixed(2), overHrs.toFixed(2), otherHrs.toFixed(2)];
  };

  const layoffButton = (id) => {
    const laidoffArray = NewTimeEntries[`Employee${id}`]?.time || [];
    const laidoff = laidoffArray.filter((vA) => (vA.data.LaidOff || false) === true).length > 0;
    const notLaidoff = laidoffArray.filter(vA => (vA.data.LaidOff || false) === false).length > 0;
    if (laidoff && notLaidoff) {
      return (
        <Tooltip title="User has had time added since being laid off">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = laidoffArray.map((vT) => layoffOverride(vT, false));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}
          >
            <FontAwesomeIcon icon={faUserXmark} />
          </Button>
        </Tooltip>
      );
    }
    if (laidoff && !notLaidoff) {
      return (
        <Tooltip title="Un-Layoff User">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = laidoffArray.map((vT) => layoffOverride(vT, false));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}

          >
            <FontAwesomeIcon icon={faUser} />
          </Button>
        </Tooltip>
      );
    }
    if (!laidoff && !notLaidoff) {
      // set 
      return (
        <Tooltip title="No Time For User">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              toast.info('No records to lay off');
            }}

          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Tooltip>
      );
    }

    if (!laidoff && notLaidoff) {
      // set  
      return (
        <Tooltip title="Layoff User">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = laidoffArray.map((vT) => layoffOverride(vT, true));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}

          >
            <FontAwesomeIcon icon={faUserSlash} />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  const columns = [
    {
      field: 'EmployeeDisplay',
      headerName: 'Employee',
      flex: 1,
      renderCell: (params) => {
        if (params.rowNode.isAutoGenerated) return '';
        const invalidP = Piccs.filter(p => p.Status === 'Inactive');
        const jctmstids = invalidP.map(p => p.jctmstid);
        const invalidPiccs = params.row.Piccs.filter(p => jctmstids.includes(p));
        const color = invalidPiccs.length > 0 ? 'red' : 'black';
        return (<span style={{ color }}>{params.row.EmployeeDisplay}</span>);
      },
    },
    {
      field: 'Mon',
      headerName: 'Mon',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Mon) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Mon)
        }}
      />),
    },
    {
      field: 'Tue',
      headerName: 'Tue',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Tue) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Tue)
        }}
      />)
    },
    {
      field: 'Wed',
      headerName: 'Wed',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Wed) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Wed)
        }}
      />)
    },
    {
      field: 'Thu',
      headerName: 'Thu',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Thu) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Thu)
        }}
      />)
    },
    {
      field: 'Fri',
      headerName: 'Fri',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Fri) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Fri)
        }}
      />)
    },
    {
      field: 'Sat',
      headerName: 'Sat',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Sat) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Sat)
        }}
      />)
    },
    {
      field: 'Sun',
      headerName: 'Sun',
      width: 100,
      renderCell: (params) => (<span style={{ color: getColor(params.row.Sun) }}
        dangerouslySetInnerHTML={{
          __html: displayDailyTotalHrs(params.row.Sun)
        }}
      />)
    },
    {
      field: 'Total',
      headerName: 'Total',
      width: 100,
      renderCell: (params) => (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span style={{ width: '50%', textAlign: 'right', fontWeight: 'bold' }}>RG: </span>
          <span style={{ width: '30%', textAlign: 'right' }}>{CrewHours(params.row)[0]}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span style={{ width: '50%', textAlign: 'right', fontWeight: 'bold' }}>OT: </span>
          <span style={{ width: '30%', textAlign: 'right' }}>{CrewHours(params.row)[1]}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <span style={{ width: '50%', textAlign: 'right', fontWeight: 'bold' }}>OTH: </span>
          <span style={{ width: '30%', textAlign: 'right' }}>{CrewHours(params.row)[2]}</span>
        </div>

      </div>)
    },
    {
      field: 'Verified',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {verifiedButton(params)}
        </div>
      )
    }


  ];

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
  const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
    setDetailPanelExpandedRowIds(
      newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
    );
  }, []);

  const apiRef = useGridApiRef();
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <TimeSummaryDetailPanel
      row={row}
      apiRef={apiRef}
      queuePiccs={queuePiccs}
      refreshTimeEntries={getNewJobsRecords}
    />,
    [CurrentDate.value],
  );
  const selectFilter = (s) => {
    setFilterSelected(s);
    getNewTimeEntries(NewTimeEntries, true, s);
  };
  LicenseInfo.setLicenseKey(
    '3531a0fd8b8f5639cd90e1f16f9621beTz0xMDcwNzksRT0xNzY5OTkwMzk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
  );

  return (
    <Grid container>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        Time Summary for Crew Members in{' '}
        <b>{`"${CurrentForeman.shortDisplay}" Crew`}</b> for Week Ending{' '}
        <b>{dayjs(weekEndDate(CurrentDate.value)).format('MMMM D, YYYY')}</b>
      </Grid>
      <ToolTipIconButton icon={faQuestionCircle} label="Help" tooltipText="For Assistance with the New Updates" onClick={() => window.open('https://aldridge.freshservice.com/support/solutions/articles/31000073851', '_blank')} />

      <Grid item xs={12}>
        {Piccs.filter(p => p.Status === 'Inactive').length > 0 ? (
          <Alert color='warning'>
            {Piccs.filter(p => p.Status === 'Inactive').map((p) => (
              <div key={p}>{`[${p?.JobNumber}.${p?.SubJobNumber}] "${p?.PICC} - ${p?.Description}" is currently being used and is INACTIVE in JobCost. Please contact your Project Manager or JobCost to reactivate the PICC.`}</div>
            ))}
            <br />
            <div style={{ textAlign: 'left' }}>
              Employee records with affected PICCs will be highlighted in
              red.
            </div>
            <div style={{ textAlign: 'left' }}>
              Please allow up to an hour after the code has been reactivated
              to see any changes.
            </div>
          </Alert>
        ) : null}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {
            CurrentForeman.value.length > 0 &&
            <InputAutocomplete
              firebaseConfig={firebaseConfig}
              label='Group'
              firestoreOptions={{
                method: 'get',
                collection: 'Group-Manager',
                where: [['data.Status', '==', 'Active'], ['data.Foreman', '==', CurrentForeman.value[0]]],
                orderBy: ['data.GroupName'],
                valueKey: 'id',
              }}
              name='GroupName'
              onChange={onGroupChange}
              optionDisplay={(opts) => opts?.data?.GroupName || ""}
              value={GroupName || []}
              enterprise={false}
            />
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={{ textAlign: 'left', width: 'fit-content' }}>
          <div style={{ border: 'solid', borderColor: '#3f51b5', borderRadius: '5px', marginTop: '2px' }}>
            <Button disableRipple style={{ borderRadius: '3px 0px 0px 3px' }} className={filterSelected === 0 ? "filterBtnSelected" : "filterBtn"} onClick={() => selectFilter(0)}>All</Button>
            <Button disableRipple className={filterSelected === 1 ? "filterBtnSelected" : "filterBtn"} onClick={() => selectFilter(1)}>Reg</Button>
            <Button disableRipple className={filterSelected === 2 ? "filterBtnSelected" : "filterBtn"} onClick={() => selectFilter(2)}>Over</Button>
            <Button disableRipple className={filterSelected === 3 ? "filterBtnSelected" : "filterBtn"} onClick={() => selectFilter(3)}>Dbl</Button>
            <Button disableRipple className={filterSelected === 4 ? "filterBtnSelected" : "filterBtn"} onClick={() => selectFilter(4)}>HL</Button>
            <Button disableRipple className={filterSelected === 5 ? "filterBtnSelected" : "filterBtn"} onClick={() => selectFilter(5)}>Per Diem</Button>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '8px',
          overflow: 'auto',
          height: '750px'
        }}>
          <DataGridPremium
            density="compact"
            className="aldg-doubleBorder"
            columns={columns}
            rows={aggTimeDisplay}
            getRowId={(row) => row.Employee}
            rowHeight={125}
            sx={{ flex: 1 }}
            getDetailPanelContent={getDetailPanelContent}
            apiRef={apiRef}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            disableDensitySelector
            disableExport
            disableColumnFilter
            disableColumnSelector

            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TimeSummaryLegend />
      </Grid>
    </Grid>
  );
};

export default TimeSummaryList;
